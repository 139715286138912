@import "../../Styles/theme.scss";

.just_released_container {
  display: flex;
  align-items: center;
  background-color: $support2;
  padding: 22px;
  border-radius: 4px;
  max-width: 100%; // 80% when showing the 'watch now' button
}

.just_released_icon_container {
  margin-right: 22px;
}

.title_and_subheading_container {
  display: flex;
  flex-direction: column;
}

.just_released_title {
  font-size: $heading4;
  font-weight: $mediumWeight;
  color: $neutral7;
  margin-bottom: 8px;
}

.just_released_subheading {
  font-size: $bodySmall;
  font-weight: $regular;
  color: $neutral7;
  line-height: 1.2;
}

.just_released_clickable_text {
  font-size: $bodyMedium;
  font-weight: $mediumWeight;
  color: $neutral6;
  text-decoration-line: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 3.5px;
  cursor: pointer;
  margin-left: 29px;
  text-wrap: nowrap;
}

.iframe_container {
  width: 100%;
  height: 80vh;
  margin-top: 50px;
}

.description_and_button_container {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.release_description_text {
  font-size: $bodyMedium;
  color: $neutral7;
  margin-right: 30px;
}

@media (max-width: 1194px) {
  .just_released_container {
    max-width: none;
  }
}

@media (max-width: 750px) {
  .just_released_container {
    flex-wrap: wrap;
  }
  .just_released_clickable_text {
    text-wrap: wrap;
    margin-left: 0;
    margin-top: 12px;
  }
}
