@import "../../Styles/theme.scss";

.devices {
  display: flex;
  flex: 1;
  justify-content: space-between;
  box-sizing: border-box;
}

.devices_center {
  justify-content: center;
}

.loading_sensors {
  text-align: center;
  align-self: center;
  flex-direction: column;
  display: flex;
  justify-content: center;
  padding-bottom: 48px;
}

.card {
  display: flex;
  flex: 1;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0;
  h1 {
    font-size: $baseFontSize;
    font-weight: 500;
    margin: 12px 21px;
  }
  hr {
    width: 100%;
    border-top: 0.5px solid $neutral2;
    margin: 0;
  }
}

.installationNotes {
  margin: 20px;

  h2 {
    font-size: 14px;
    font-weight: 500;
    margin: 0;
    margin-bottom: 6px;
  }
  p {
    font-size: 14px;
  }
}

.error {
  color: $risk3;
}

.installationNotes_buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;
}

.devices_right,
.devices_left {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 20px;
}

.sensors {
  flex: 1;
  background-color: $white;
  width: 100%;
  border-radius: 10px;
  box-sizing: border-box;
  position: relative;
  h2 {
    margin-top: 0;
  }
}

.sensors_sensor_location {
  font-size: $small-font-size;
  line-height: 1.2rem;
}

.buttons {
  width: 200px;
  align-self: flex-end;
  display: flex;
  flex-direction: row-reverse;
}

a {
  color: $secondary3;
  text-decoration: underline;
}

@media (max-width: 800px) {
  .content {
    padding: $mobile-padding;
  }
  .devices {
    margin-top: 12px;
    padding: $mobile-padding;
    flex-direction: column;
  }
  .devices_left {
    width: 100%;
    flex: 1;
    padding: 0;
  }
  .devices_right {
    width: 100%;
    flex: 1;
    justify-content: space-between;
    padding: 0;
  }
  .buttons {
    width: 100%;
    flex-direction: column;
  }
}
