@import "../../Styles/theme.scss";

.sensor_status_tag {
  display: flex;
  height: auto;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  color: $neutral7;
  gap: 8px;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;

  cursor: pointer;
}

.icon {
  display: flex;
  align-items: center;
}

.online {
  background-color: $expected1;
}

.offline {
  background-color: $risk1;
}

.warning {
  background-color: $unexpected1;
}

.error {
  background-color: $neutral2;
  color: $neutral5;
}

@media (hover: hover) {
  .online:hover {
    background-color: #e0f3c0;
  }

  .offline:hover {
    background-color: $risk2;
  }

  .warning:hover {
    background-color: $unexpected2;
  }

  .error:hover {
    background-color: $neutral3;
  }
}
