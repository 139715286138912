@import "../../Styles/theme.scss";

.hide {
  display: none;
}

/* 1019px x 1319px is our default size of a PDF */
.pdf {
  width: 1000px;
  color: $neutral6;
  height: 1414px;
  background-color: $white;
  display: flex;
  flex-direction: column;
  margin: 24px 36px;
  overflow: hidden;
  page-break-after: always;
  position: relative;
  padding: 80px 50px 60px 50px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  h1 {
    color: $neutral7;
    font-weight: $semiBold;
    margin-bottom: 16px;
    font-size: $heading1;
    margin-top: 36px;
  }
  h2 {
    // "overview" and "summary" headings
    color: $neutral7;
    font-weight: $mediumWeight;
    font-size: $heading3;
  }
  h3 {
    // "details" headings
    color: $neutral7;
    font-weight: $semiBold;
    font-size: $subHeading;
    line-height: 28px;
    text-transform: none;
    margin-bottom: 0;
    margin-top: 0;
    display: inline-block;
    padding-left: 1em;
    text-indent: -1em;
  }
  ul {
    margin: 24px 0 24px 20px;
    list-style: disc outside;
    li {
      font-size: $bodySmall;
      font-weight: $mediumWeight;
    }
  }
}

.pdf_first {
  @extend .pdf;
  padding-top: 20px;
}

.copy {
  width: 100%;
  margin-bottom: 25px;
}
.copy_paragraph {
  font-weight: $mediumWeight;
  margin-top: 10px;
  margin-bottom: 0;
  color: $neutral5;
  line-height: 14px;
}
.copy_paragraph_bold {
  @extend .copy_paragraph;
  color: $neutral7;
}

.copy_button {
  margin-top: 12px;
  width: 219px;
}

.dateRange {
  font-size: $heading5;
  font-weight: $mediumWeight;

  margin: 0;
}

.details {
  display: flex;
  margin-bottom: 72px;
  color: $neutral7;
  margin-top: 40px;
}
.gridItem {
  font-size: 14px;
}
.gridItem_bold {
  @extend .gridItem;
  font-weight: $mediumWeight;
}

.details_section {
  display: flex;
  flex-direction: column;
  margin-right: 180px;
}
.details_section_grid {
  margin-top: 8px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2px 36px;
}

.flex_start {
  justify-content: flex-start !important;
}
.space_evenly {
  justify-content: space-evenly !important;
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 7px;
  img {
    object-fit: contain;
  }
}

.pageNumber {
  position: absolute;
  font-size: $bodyExtraSmall;
  color: $neutral6;
  bottom: 50px;
  right: 50%;
}

.line_break {
  border: 1px solid $neutral3;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  width: 100%;
  margin: 48px 0;
}

.section {
  h2 {
    margin-top: 0;
    margin-bottom: 48px;
  }
}

.section_question {
  display: flex;
  justify-content: space-between;
  div {
    flex: 1;
  }
}

.section_question_text {
  max-width: 440px;
}
